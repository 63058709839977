import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <h2 className="hero-subtitle">
          {subtitle || "Recipes"}
        </h2>
        <ul>
          <li><p className="text-left"><a href="/biryani">Chicken biryani</a></p></li>
        </ul>
        <br/>
        <br/>
        <br/>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {"To "}
            <span className="text-color-main">{"Christina Le"}</span>
            <br />
          </h1>

          <h2 className="hero-subtitle">
            {subtitle || "the birthday girl, a cutie and a ray of sunshine"}
          </h2>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
